var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("BlockTitle", {
        staticClass: "table-title",
        attrs: { title: "近一年计薪周期展示", "is-bold": true },
      }),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "payPeriod",
          pagination: false,
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "payPeriod",
            fn: function (ref) {
              var scope = ref.scope
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.moment(scope.record.payPeriod).format("YYYY年MM月")
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "startDate",
            fn: function (ref) {
              var scope = ref.scope
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.moment(scope.record.startDate).format("YYYY-MM-DD")
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "endDate",
            fn: function (ref) {
              var scope = ref.scope
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.moment(scope.record.endDate).format("YYYY-MM-DD")
                    ) +
                    " "
                ),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }